import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { Step4Props } from '../typedefs/props'
import FormStep4 from '../components/FormStep4'
import { SubscriptionProvider } from '../components/SubscriptionProvider'
import { Subscription } from '../components/SubscriptionContext'

import image4 from '../images/step-4.svg'
import './Step4.css'

const Step4: React.FC<Step4Props> = ({ apiUrl, baseUrl, webUrl }) => {
  return (
    <div className="step-4">
      <Container fluid>
        <Row>
          <Col md={4}>
          <p>Dosis mensual - Paso 4 de 4</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
                <div className="block">
                <h3>Detalle de tu suscripción:</h3>

                <SubscriptionProvider>
                  <div>
                    <Subscription>
                    {({ quantity, weight, price, hasDelivery, delivery, hasDiscount, discount, finalPrice }) => {
                      return (
                        <div>
                          <p>{quantity} bolsas ({weight}) = S/ {price}</p>
                          { hasDelivery() ? (<p>Costo de envío = S/ {delivery}</p>) : '' }
                          { hasDiscount() ? (<p>Descuento = - S/ {discount}</p>) : '' }
                          <p><strong>TOTAL MENSUAL =  S/ {finalPrice}</strong></p>
                        </div>
                      )
                    }}
                    </Subscription>
                    <FormStep4 apiUrl={apiUrl} baseUrl={baseUrl} webUrl={webUrl}/>
                  </div>
                </SubscriptionProvider>
                </div>
          </Col>
          <Col md={4}>
          <img src={image4} className="icon" alt="Paso 4" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Step4

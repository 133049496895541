import axios, { AxiosError } from 'axios'
import { Step1FormData, Step2FormData, Step3FormData } from '../typedefs/props'
import { ErrorCodes } from '../typedefs/enums'
import { SaleRequest, SaleErrorResponse, SendOTPRequest, UserRequest, UserErrorResponse, ValidateOTPRequest, SaleResponse, SubscriptionRequest, ChangeCardRequest, NewCardRequest } from '../typedefs/request'

export const saveSheetStep1 = async (apiUrl: string, data: Step1FormData) => {
  let status = false
  try {
    const response = await axios.patch(`${apiUrl}/google/sheet/step-1`, data)
    status = response.data.status
  } catch (error) {
    console.error(error)
  }
  return status
}

export const saveSheetStep2 = async (apiUrl: string, data: Step2FormData) => {
  let status = false
  try {
    const response = await axios.patch(`${apiUrl}/google/sheet/step-2`, data)
    status = response.data.status
  } catch (error) {
    console.error(error)
  }
  return status
}

export const saveSheetStep3 = async (apiUrl: string, data: Step3FormData) => {
  let status = false
  try {
    const response = await axios.patch(`${apiUrl}/google/sheet/step-3`, data)
    status = response.data.status
  } catch (error) {
    console.error(error)
  }
  return status
}

export const createUser = async (apiUrl: string, data: UserRequest) => {
  let user: string | UserErrorResponse = { code: ErrorCodes.UNHANDLED }
  try {
    const response = await axios.post(`${apiUrl}/user`, data)
    if (response.data.status) {
      user = response.data.data
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      user = error.response?.data.data
    }
    console.error(error)
  }
  return user
}

export const updateUser = async (apiUrl: string, userId: string, data: UserRequest) => {
  let user: string | UserErrorResponse = { code: ErrorCodes.UNHANDLED }
  try {
    const response = await axios.patch(`${apiUrl}/user/${userId}`, data)
    if (response.data.status) {
      user = response.data.data
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      user = error.response?.data.data
    }
    console.error(error)
  }
  return user
}

export const createSale = async (apiUrl: string, data: SaleRequest) => {
  let sale: SaleResponse | SaleErrorResponse = { code: ErrorCodes.UNHANDLED }
  try {
    const response = await axios.post(`${apiUrl}/sale`, data)

    if (response.data.status) {
      sale = response.data.data
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      sale = error.response?.data.data
    }
    console.error(error)
  }
  return sale
}

export const updateSale = async (apiUrl: string, saleId: string, data: SaleRequest) => {
  let sale: SaleResponse | SaleErrorResponse = { code: ErrorCodes.UNHANDLED }
  try {
    const response = await axios.patch(`${apiUrl}/sale/${saleId}`, data)
    if (response.data.status) {
      sale = response.data.data
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      sale = error.response?.data.data
    }
    console.error(error)
  }
  return sale
}

export const sendOTP = async (apiUrl: string, data: SendOTPRequest) => {
  try {
    await axios.post(`${apiUrl}/otp`, data)
  } catch (error) {
    console.error(error)
  }
}

export const validateOTP = async (apiUrl: string, data: ValidateOTPRequest) => {
  let user: string | UserErrorResponse = { code: ErrorCodes.UNHANDLED }
  try {
    const response = await axios.patch(`${apiUrl}/otp`, data)

    if (response.data.status) {
      user = response.data.data
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      user = error.response?.data.data
    }
    console.error(error)
  }
  return user
}

export const sendLinkCard = async (apiUrl: string, data: ChangeCardRequest): Promise<boolean> => {
  let status = false
  try {
    const response = await axios.post(`${apiUrl}/card/send-link`, data)

    if (response.data.status) {
      status = response.data.status
    }
  } catch (error: unknown) {
    console.error(error)
  }
  return status
}

export const saveCard = async (apiUrl: string, data: NewCardRequest) => {
  let status = false
  try {
    const response = await axios.patch(`${apiUrl}/card`, data)

    if (response.data.status) {
      status = response.data.status
    }
  } catch (error: unknown) {
    console.error(error)
  }
  return status
}

export const createSubscription = async (apiUrl: string, data: SubscriptionRequest): Promise<boolean> => {
  let status = false
  try {
    const response = await axios.post(`${apiUrl}/subscription`, data)
    status = response.data.status
  } catch (error) {
    console.error(error)
  }
  return status
}

import { createContext } from 'react'
import { SubscriptionContextProps } from '../typedefs/props'

const SubscriptionContext = createContext<SubscriptionContextProps>({
  discount: '0',
  price: '0',
  delivery: '0',
  finalPrice: '',
  quantity: 0,
  weight: '',
  hasDelivery: () => false,
  hasDiscount: () => false,
  setDiscount: (discount: string) => {}
})

export const { Consumer: Subscription } = SubscriptionContext
export default SubscriptionContext

import { Step1FormData, Step2FormData, Step3FormData, Step4FormData } from '../typedefs/props'
import { DocumentType } from '../typedefs/enums'

type Steps = Step1FormData | Step2FormData | Step3FormData | Step4FormData
type StepNames = 'step1' | 'step2' | 'step3' | 'step4'

const defaultSteps = (userId: string) => {
  return ({
    step1: { grinding: '', coffeeType: '', userId },
    step2: { quantity: 0, userId },
    step3: {
      terms: '',
      email: '',
      phone: '',
      name: '',
      lastName: '',
      documentNumber: '',
      documentType: DocumentType.DNI,
      address: '',
      reference: '',
      department: '',
      province: '',
      district: '',
      userId
    },
    step4: {
      couponCode: ''
    }
  })
}

export const readSteps = (step: StepNames, userId: string) => {
  let currentStepData: Steps

  try {
    currentStepData = JSON.parse(sessionStorage.getItem('subscriptionFields') || '')[step]
    if (!currentStepData) {
      currentStepData = defaultSteps(userId)[step]
    }
  } catch (e) {
    currentStepData = defaultSteps(userId)[step]
  }
  return currentStepData
}

export const saveSteps = (step: StepNames, data: Steps) => {
  const userId = sessionStorage.getItem('userId') || 'unknown'

  sessionStorage.setItem('subscriptionFields', JSON.stringify({
    ...defaultSteps(userId),
    ...JSON.parse(sessionStorage.getItem('subscriptionFields') || '{}'),
    [step]: data
  }))
}

export const weight = ({ quantity }: Step2FormData) => {
  const quantityNumber = typeof quantity === 'string' ? Number.parseInt(quantity, 10) : quantity
  return quantityNumber === 4 ? '1 kilo' : `${quantityNumber * 250}gr`
}

/* eslint-disable no-unused-vars */

export enum DocumentType {
    DNI = 'DNI',
    PASSPORT = 'PASSPORT'
}

export enum ErrorCodes {
    UNHANDLED = '001',
    ALREADY_HAS_SUBSCRIPTION = '002',
    ALREADY_EXIST = '003',
    COUPON_NOT_FOUND = '004',
    COUPON_SUCCESS = '005',
    COUPON_NOT_AVAILABLE = '006',
    COUPON_NOT_APPLICABLE = '007'
}

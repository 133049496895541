import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FormStep2 from '../components/FormStep2'
import './Step2.css'
import image2 from '../images/step-2.svg'
import { Step2Props } from '../typedefs/props'

const Step2: React.FC<Step2Props> = ({ sheetAPIUrl }) => {
  return (
    <div className="step-2">
      <Container fluid>
      <Row>
        <Col md={6}>
        <p>Dosis mensual - Paso 2 de 4</p>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
        <img src={image2} className="icon" alt="Paso 2" />
        </Col>
        <Col md={1}/>
        <Col md={6}>
          <Row>
            <Col md={10}>
              <div className="block">
              <h3>Elige la cantidad de café que deseas al mes</h3>
              </div>
              <FormStep2 sheetAPIUrl={sheetAPIUrl}/>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default Step2

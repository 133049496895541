import React, { useEffect, useState } from 'react'
import { Step2FormData, SubscriptionCheckoutProps } from '../typedefs/props'
import SubscriptionContext from './SubscriptionContext'
import { readSteps, weight } from '../helpers/stepsData'

export const SubscriptionProvider: React.FC<SubscriptionCheckoutProps> = (
  props: SubscriptionCheckoutProps
) => {
  const userId = sessionStorage.getItem('userId') || 'unknown'
  const [discount, setDiscount] = useState(sessionStorage.getItem('discount') || '0')
  const [finalPrice, setFinalPrice] = useState('')

  const delivery = sessionStorage.getItem('delivery') || '0'
  const price = sessionStorage.getItem('price') || '0'
  const step2 = readSteps('step2', userId) as Step2FormData

  const getFinalPrice = () => {
    return (Number.parseFloat(price) + Number.parseFloat(delivery) - Number.parseFloat(discount)).toFixed(2)
  }

  useEffect(() => {
    setFinalPrice(getFinalPrice())
  }, [discount])

  return (
    <SubscriptionContext.Provider
      value={{
        discount,
        price,
        delivery,
        finalPrice,
        quantity: typeof step2.quantity === 'string' ? Number.parseInt(step2.quantity, 10) : step2.quantity,
        weight: weight(step2),
        hasDelivery: () => delivery !== '0',
        hasDiscount: () => discount !== '0',
        setDiscount
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  )
}

import districts from './districts.json'
import provinces from './provinces.json'
import departments from './departments.json'

type UbigeoOption = {
  value: string;
  text: string
}

type UbigeoDistrict = {
  code: string;
  district: string;
  province: string;
}

type UbigeoProvince = {
  code: string;
  province: string;
  department: string;
}

type UbigeoDepartment = {
  code: string;
  department: string;
}

export const getDepartments = (): UbigeoOption[] => {
  return departments.map((deparment: UbigeoDepartment) => {
    return {
      value: deparment.code,
      text: deparment.department
    }
  })
}

export const getProvinces = (deparment: string): UbigeoOption[] => {
  return provinces.filter((province) => {
    return (deparment !== '' && province.department === deparment) || deparment === ''
  }).map((province: UbigeoProvince) => {
    return {
      value: province.code,
      text: province.province
    }
  })
}

export const getDistricts = (province: string): UbigeoOption[] => {
  return districts.filter((district) => {
    return (province !== '' && district.province === province) || province === ''
  }).map((district: UbigeoDistrict) => {
    return {
      value: district.code,
      text: district.district
    }
  })
}

import React from 'react'
import './Button.css'

const Button: React.FC<{label: string, link: string, mainClass?: string}> = ({ label, link, mainClass }) => {
  const openLink = () => {
    window.open(link, '_self')
  }
  return (
    <button className={`cofaine-button ${mainClass}`} onClick={openLink}>{label}</button>
  )
}

export default Button

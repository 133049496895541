import 'bootstrap/dist/css/bootstrap-grid.min.css'
import './App.css'

import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Header from './components/Header'
import TopBar from './components/TopBar'
import Footer from './components/Footer'
import { BottomNavbarProp, NavbarProp } from './typedefs/props'
import Step1 from './pages/Step1'
import Step2 from './pages/Step2'
import Step3 from './pages/Step3'
import Step4 from './pages/Step4'
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import ValidationOTP from './pages/ValidationOTP'
import ProtectedRoute from './components/ProtectedRoute'
import ChangeCard from './pages/ChangeCard'
import NewCard from './pages/NewCard'

const webUrl = process.env.REACT_APP_WEB_URL || ''
const storeUrl = process.env.REACT_APP_STORE_URL || ''
const apiUrl = process.env.REACT_APP_PAYMENT_API_URL || ''
const sheetAPIUrl = process.env.REACT_APP_GOOGLE_API_URL || ''
const baseUrl = '//' + window.location.host

const topNavBarLinks: NavbarProp[] = [
  { label: 'Origen', url: `${webUrl}/nuestro-origen` },
  { label: 'Tienda', url: storeUrl },
  { label: 'Planes', url: `${webUrl}/precios-suscripcion` }
]

const bottomNavBarSocialLinks: BottomNavbarProp[] = [
  { icon: faInstagram, alt: 'Instagram', url: 'https://www.instagram.com/cofaine.pe' },
  { icon: faFacebookF, alt: 'Facebook', url: 'https://www.facebook.com/cofaine.pe' },
  { icon: faTwitter, alt: 'Twitter', url: 'https://twitter.com/cofainepe' }
]

const App = () => {
  return (
    <div>
    <Router>
    <TopBar link={`${webUrl}/dosis-mensual`}/>
    <Header links={topNavBarLinks} webUrl={webUrl}/>
    <Routes>
        <Route path='/dosis-mensual-paso-1' element={
          <Step1 webUrl={webUrl} sheetAPIUrl={sheetAPIUrl}/>
        }/>
        <Route path='/dosis-mensual-paso-2' element={
          <ProtectedRoute stepToValidate='step2'>
            <Step2 sheetAPIUrl={sheetAPIUrl} />
          </ProtectedRoute>
        }/>
        <Route path='/dosis-mensual-paso-3' element={
          <ProtectedRoute stepToValidate='step3'>
            <Step3 sheetAPIUrl={sheetAPIUrl} apiUrl={apiUrl}/>
          </ProtectedRoute>
        }/>
        <Route path='/dosis-mensual-paso-4' element={
          <ProtectedRoute stepToValidate='step4'>
            <Step4 webUrl={webUrl} apiUrl={apiUrl} baseUrl={baseUrl}/>
          </ProtectedRoute>
        }/>
        <Route path='/validacion-otp' element={
          <ProtectedRoute stepToValidate='otp'>
            <ValidationOTP apiUrl={apiUrl}/>
          </ProtectedRoute>
        } />
        <Route path='/cambio-de-tarjeta' element={
            <ChangeCard apiUrl={apiUrl}/>
        } />
        <Route path="/nueva-tarjeta" element={
          <ProtectedRoute stepToValidate='tarjeta'>
            <NewCard webUrl={webUrl} apiUrl={apiUrl} baseUrl={baseUrl}/>
          </ProtectedRoute>
        } />
        <Route path="*" element={
          <Navigate to="/dosis-mensual-paso-1" />
        } />
    </Routes>
    </Router>
    <Footer socialLinks={bottomNavBarSocialLinks} webUrl={webUrl} baseUrl={baseUrl}/>
    </div>
  )
}

export default App

import React, { useState, useEffect } from 'react'
import CulqiContext from './CulqiContextV4'
import { CulqiError, CulqiOptionsV4, CulqiProviderV4Props, CulqiSettingsV4, CulqiToken, ValidationPaymentMethods } from '../typedefs/culqi'

const culqiMessages = {
  welcome: 'checkout_bienvenido',
  closed: 'checkout_cerrado'
}

const baseCulqiUrl = 'https://checkout.culqi.com'
const culqiId = 'culqi-js'
const culqiUrl = `${baseCulqiUrl}/js/v4`

export const CulqiProviderV4: React.FC<CulqiProviderV4Props> = (props: CulqiProviderV4Props) => {
  const [amount, setAmount] = useState<number>(props.settings.amount || 0)
  const [token, setToken] = useState<CulqiToken | null>(null)
  const [error, setError] = useState<CulqiError | null>(null)

  useEffect(() => {
    if (props.settings.amount) {
      setAmount(props.settings.amount)
    }
  }, [props.settings.amount])

  const getCulqiSettings = (): CulqiSettingsV4 => {
    const {
      currency = 'PEN',
      description = '',
      title = '',
      order
    } = props.settings

    return {
      amount,
      currency,
      description,
      title,
      order
    }
  }

  const initCulqi = () => {
    const { publicKey, options } = props

    setCulqiOptions(options);
    (window as any).Culqi.publicKey = publicKey
    requestAnimationFrame(() => {
      setCulqiSettings(getCulqiSettings())
    });
    (window as any).culqi = () => {
      if (!props.enableEvents) {
        const token = (window as any).Culqi.token

        if (token) {
          setToken(token)
          props.onToken && props.onToken(token)
        }
      }
    }
  }

  const onCulqiLoad = (e: any) => {
    if ((window as any).Culqi) {
      initCulqi()
    }
  }

  const onCulqiEvent = (messageEvent: {
    origin: string;
    data: string | CulqiToken | CulqiError;
  }) => {
    const { origin, data } = messageEvent
    console.log(origin, data)

    const { onClose, onError, onToken } = props

    if (origin !== baseCulqiUrl) return

    if (typeof data === 'string' && data === culqiMessages.closed) {
      onClose && onClose()
      initCulqi()
    }

    if (typeof data === 'object') {
      const { object } = data
      if (!object) return
      if (object === 'token') {
        setToken(data)
        onToken && onToken(data)
      } else if (object === 'error') {
        setError(data)
        onError && onError(data)
      }
    }
  }

  const openCulqi = () => {
    if ((window as any).Culqi) {
      setCulqiSettings(getCulqiSettings());
      (window as any).Culqi.open()
    }
  }

  const setCulqiOptions = (options: CulqiOptionsV4) => {
    if (Object.keys(options).length > 0 && (window as any).Culqi) {
      (window as any).Culqi.options(options)
    }
  }

  const setCulqiSettings = (settings: CulqiSettingsV4) => {
    if ((window as any).Culqi) {
      (window as any).Culqi.settings(settings)
    }
  }

  const getPaymentOptionsAvailable = (): ValidationPaymentMethods => {
    setCulqiSettings(getCulqiSettings());
    (window as any).Culqi.validationPaymentMethods()
    return (window as any).Culqi.paymentOptionsAvailable
  }

  useEffect(() => {
    if (!props.publicKey) return

    const script = document.createElement('script')
    script.id = culqiId
    script.src = culqiUrl
    script.async = true
    script.onload = onCulqiLoad
    document.body.appendChild(script)
    window.addEventListener('message', onCulqiEvent, false)

    return () => {
      document.body.removeChild(script)
      window.removeEventListener('message', onCulqiEvent, false);
      (window as any).culqi = undefined
    }
  }, [props.publicKey])

  useEffect(() => {
    if (!props.publicKey) {
      throw new Error('Please pass along a publicKey prop.')
    }
  }, [props.publicKey])

  return (
    <CulqiContext.Provider
      value={{
        getPaymentOptionsAvailable,
        setAmount,
        openCulqi,
        amount,
        token,
        error
      }}
    >
      {props.children}
    </CulqiContext.Provider>
  )
}

const generateId = (length = 16) => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('')
  let id = ''
  for (let i = 0; i < length; i += 1) {
    id += chars[Math.floor(Math.random() * chars.length)]
  }
  return id
}

export default generateId

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Field, Formik, FormikProps } from 'formik'

import generateId from '../helpers/generateId'
import { FormStep1Props, Step1FormData } from '../typedefs/props'
import { saveSheetStep1 } from '../helpers/service'
import { readSteps, saveSteps } from '../helpers/stepsData'
import './Form.css'
import { errorMessage, hideLoading, startLoader, successMessage } from '../helpers/sweetAlerts'

let userId: string
if (!sessionStorage.getItem('userId')) {
  userId = generateId()
  sessionStorage.setItem('userId', userId)
} else {
  userId = sessionStorage.getItem('userId') || 'unknown'
}

const coffeeTypes = [
  { label: 'Geisha Blend  (intenso)', value: 'blend' },
  { label: 'Geisha Gold (suave)', value: 'gold' },
  { label: 'Ambas', value: 'both' }
]

const grindings = [
  { label: 'Fina', value: 'fine' },
  { label: 'Media', value: 'average' },
  { label: 'Gruesa', value: 'gross' },
  { label: 'Grano entero', value: 'whole_grain' }
]

const FormStep1: React.FC<FormStep1Props> = ({ sheetAPIUrl }) => {
  const navigate = useNavigate()
  if (!sessionStorage.getItem('userId')) {
    sessionStorage.setItem('userId', userId)
  }
  const step1 = readSteps('step1', userId) as Step1FormData

  const validate = (values: Step1FormData) => {
    const errors: Partial<Step1FormData> = {}
    if (!values.coffeeType) {
      errors.coffeeType = 'El campo \'presentación de café\' es obligatorio.'
    }
    if (!values.grinding) {
      errors.grinding = 'El campo \'tipo de molienda\' es obligatorio.'
    }
    return errors
  }

  const formikSubmit = async (values: Step1FormData) => {
    startLoader()
    const status = await saveSheetStep1(sheetAPIUrl, values)

    hideLoading()

    if (status) {
      successMessage()
      saveSteps('step1', values)
      navigate('/dosis-mensual-paso-2')
    } else {
      errorMessage()
    }
  }

  return (
    <Formik initialValues={step1} onSubmit={formikSubmit} validate={validate}>
    {({ touched, errors }: FormikProps<Step1FormData>) => (
      <Form>
        <div id="coffeeTypeGroup" className='title'>
          <span>Presentación de café</span> <span className="description">(obligatorio)</span>
        </div>
        <div role="group" className="group" aria-labelledby="coffeeTypeGroup">
          {
            coffeeTypes.map(({ label, value }, index) => (
            <label key={`coffeeType-${index}`}>
              <Field type="radio" name="coffeeType" value={value}/> {label}
            </label>
            ))
          }
          {touched.coffeeType && errors.coffeeType && <div className='errors'>{errors.coffeeType}</div>}
        </div>

        <div id="grindingGroup" className='title'>
          <span>Tipo de molienda</span> <span className='description'>(obligatorio)</span>
        </div>
        <div role="group" className="group" aria-labelledby="grindingGroup">
          {
            grindings.map(({ label, value }, index) => (
            <label key={`grinding-${index}`}>
              <Field type="radio" name="grinding" value={value}/> {label}
            </label>
            ))
          }
          {touched.grinding && errors.grinding && <div className='errors'>{errors.grinding}</div>}
        </div>

        <button type="submit" className='cofaine-button'>Continuar</button>
      </Form>
    )}
    </Formik>
  )
}

export default FormStep1


import Swal from 'sweetalert2'

export const startLoader = (title = 'Guardando información'): void => {
  Swal.fire({
    title,
    html: 'Por favor espere...',
    backdrop: true,
    didOpen: async () => {
      Swal.showLoading()
    },
    allowOutsideClick: () => !Swal.isLoading()
  })
}

export const hideLoading = Swal.hideLoading

export const successMessage = (title = 'Información guardada correctamente') => {
  Swal.update({ title, icon: 'success', html: '', showConfirmButton: true })
}

export const errorMessage = (title = 'Sucedió un error', html = '', showConfirmButton = true) => {
  Swal.update({ title, icon: 'error', html, showConfirmButton })
}

export const warningMessage = (title = 'Usuario ya se encuentra registrado', html = 'Por favor espere mientras se envían los codigo de validación para confirmar sus datos ...') => {
  Swal.update({
    title,
    icon: 'warning',
    html,
    showConfirmButton: false
  })
}

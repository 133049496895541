import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import FormStep1 from '../components/FormStep1'
import { Step1Props } from '../typedefs/props'
import './Step1.css'
import image1 from '../images/step-1.svg'

const Step1: React.FC<Step1Props> = ({ webUrl, sheetAPIUrl }) => {
  return (
    <div className="step-1">
      <Container fluid>
      <Row>
        <Col md={6}>
        <p>Dosis mensual - Paso 1 de 4</p>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
        <img src={image1} className="icon" alt="Paso 1" />
        </Col>
        <Col md={1}/>
        <Col md={6}>
          <div className="block">
          <h3>Elige el café y la molienda</h3>
          <p>¿Qué café y molienda elijo?  <a href={`${webUrl}/presentaciones-y-molienda`} target="_blank" rel="noreferrer">VER MÁS</a></p>
          </div>
          <FormStep1 sheetAPIUrl={sheetAPIUrl}/>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default Step1

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FooterProps } from '../typedefs/props'
import BottomBar from './BottomBar'
import './Footer.css'
import logo from '../images/asociados_camara_de_comercio_de_lima.jpg'

const Footer: React.FC<FooterProps> = ({ baseUrl, webUrl, socialLinks }) => {
  const currentPage = window.location.pathname
  const getPreviousPage = () => {
    if (currentPage.indexOf('-1') !== -1) {
      return `${baseUrl}/dosis-mensual`
    } else {
      return `${baseUrl}${currentPage.slice(0, -1) + (Number.parseInt(currentPage.charAt(currentPage.length - 1), 10) - 1)}`
    }
  }

  const hasPreviousPage = (): boolean => {
    return currentPage.indexOf('dosis-mensual-paso') !== -1
  }

  return (
    <div className="footer">
        <Row className="hr">
          <Col md={12}>
            <hr />
            { hasPreviousPage() ? (<a href={getPreviousPage()} >← VOLVER</a>) : ('')}
          </Col>
        </Row>
        <Row>
          <BottomBar socialLinks={socialLinks}/>
        </Row>
        <Row>
            <Col md={3}>
            <h3>COFAINE</h3>
            <p>¿Tienes dudas? Escríbenos a: <br/> <a href="mailto:contacto@cofaine.pe?subject=%C2%A1Hola%20Cofaine!&amp;bcc=cofaine.peru%40gmail.com" target="_blank" rel="noreferrer">contacto@cofaine.pe</a></p>
            <p><a href="sms:+51934927592">+51 934 927 592</a></p>
            </Col>
            <Col md={4}>
            <h4>¿Cómo funciona Cofaine?</h4>
            <p><span className="description">Suscríbete, elige dónde <strong>recibir tu café </strong>y nosotros nos encargamos del resto.</span></p>
            <p><span className="description">¡Es muy fácil! </span></p>
            </Col>
            <Col md={5}>
              <Row>
                <Col md={8}>
                  <h4>¿Qué es café de especialidad?</h4>
                  <p><span className="description">Café de tipo Arábica con una puntuación en taza <strong>superior a 80</strong> <strong>puntos</strong>, en una escala sobre 100, otorgada por un catador autorizado.</span></p>
                </Col>
                <Col md={4}>
                <a href="https://www.camaralima.org.pe" target="_blank" rel="noreferrer">
                <img src={logo} alt="Camara de Comercio de Lima"/>
                </a>
                </Col>
              </Row>
            </Col>
        </Row>
        <Row className="hr">
          <Col md={12}>
            <hr />
          </Col>
        </Row>
        <Row className="footer-bar">
          <Col md={3}>
          <p><a href={`${webUrl}/terminos-y-condiciones`}>Términos y condiciones</a></p>
          </Col>
          <Col md={3}>
          <p><a href={`${webUrl}/tratamiento-datos-personales`}>Tratamiento de datos personales</a></p>
          </Col>
          <Col md={2}>
          </Col>
          <Col md={4}>
          <p><span className="description">COFAINE © 2023 | Todos los derechos reservados</span></p>
          </Col>
        </Row>
    </div>
  )
}

export default Footer

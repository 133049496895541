import React from 'react'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BottomNavbarProps } from '../typedefs/props'
import './BottomBar.css'

const BottomBar: React.FC<BottomNavbarProps> = ({ socialLinks }) => {
  return (
        <nav className='bottom-bar'>
        <ul>
          {socialLinks.map((link, index) => (
            <li key={index}>
              <a href={link.url} target='_blank' rel="noreferrer"><FontAwesomeIcon icon={link.icon} /></a>
            </li>
          ))}
        </ul>
      </nav>
  )
}

export default BottomBar

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { Field, Form, Formik, FormikProps } from 'formik'
import { ChangeCardFormData, ChangeCardProps } from '../typedefs/props'

import './ChangeCard.css'
import { errorMessage, hideLoading, startLoader, successMessage } from '../helpers/sweetAlerts'
import { sendLinkCard } from '../helpers/service'

const ChangeCard: React.FC<ChangeCardProps> = ({ apiUrl }) => {
  const validate = (values: ChangeCardFormData) => {
    const errors: Partial<ChangeCardFormData> = {}
    if (!values.email) {
      errors.email = 'El campo \'correo electrónico\' es obligatorio.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = '\'correo electrónico\' inválido.'
    }
    if (!values.phone) {
      errors.phone = 'El campo \'celular\' es obligatorio.'
    }
    return errors
  }

  const formikSubmit = async (values: ChangeCardFormData): Promise<void> => {
    startLoader('Verificando datos')

    const response = await sendLinkCard(apiUrl, values)

    hideLoading()

    if (response) {
      successMessage('Revisa tu correo y continua el proceso ...')
    } else {
      errorMessage('No se encontro tus datos, intenta nuevamente...')
    }
  }

  return (
    <Formik initialValues={{ email: '', phone: '' }} onSubmit={formikSubmit} validate={validate}>
    {({ touched, errors }: FormikProps<ChangeCardFormData>) => (
      <Form>
    <div className='change-card'>
      <Container>
      <Row>
        <Col md={1}/>
        <Col md={5}>
        <h3>Validación de Datos</h3>
        <p>Ingresa el correo y número de teléfono que usaste al suscribirte. Te enviaremos un <strong>enlace seguro </strong>para que puedas registrar una nueva tarjeta.</p>
        </Col>
        <Col md={5}>
        <div id="emailGroup" className='title'>
          <span>Correo electrónico registrado</span> <span className="description">(obligatorio)</span>
        </div>
        <div role="group" className="group" aria-labelledby="emailGroup">
          <label>
            <Field type="email" name="email" placeholder="nombre@ejemplo.pe"/>
          </label>
          {touched.email && errors.email && <div className='errors'>{errors.email}</div>}
        </div>
        <div id="phoneGroup" className='title'>
          <span>Teléfono registrado</span> <span className="description">(obligatorio)</span>
        </div>
        <div role="group" className="group" aria-labelledby="phoneGroup">
          <label>
            <Field type="text" name="phone" placeholder="9 dígitos"/>
          </label>
          {touched.phone && errors.phone && <div className='errors'>{errors.phone}</div>}
        </div>
        <button type="submit" className='cofaine-button'>Validar</button>
        </Col>
        <Col md={1}/>
      </Row>
      </Container>
    </div>
    </Form>
    )}
    </Formik>
  )
}

export default ChangeCard

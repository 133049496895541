import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './ValidationOTP.css'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Step1FormData, Step2FormData, Step3FormData, ValidationOTPFormData, ValidationOTPProps } from '../typedefs/props'
import { createSale, validateOTP } from '../helpers/service'
import { useNavigate } from 'react-router-dom'
import { SaleErrorResponse, SaleResponse } from '../typedefs/request'
import { readSteps, weight } from '../helpers/stepsData'
import { errorMessage, hideLoading, startLoader, successMessage } from '../helpers/sweetAlerts'

const ValidationOTP: React.FC<ValidationOTPProps> = ({ apiUrl }) => {
  const navigate = useNavigate()
  const userId = sessionStorage.getItem('userId') || 'unknown'
  const step3 = readSteps('step3', userId) as Step3FormData
  const { email, phone } = step3 as Step3FormData

  const validate = (values: ValidationOTPFormData) => {
    const errors: Partial<ValidationOTPFormData> = {}
    if (!values.codeEmail) {
      errors.codeEmail = 'El campo \'codigo del email\' es obligatorio.'
    }
    if (!values.codePhone) {
      errors.codePhone = 'El campo \'codigo SMS\' es obligatorio.'
    }
    return errors
  }

  const formikSubmit = async (values: ValidationOTPFormData) => {
    startLoader('Verificando códigos')

    const user = await validateOTP(apiUrl, {
      email,
      phone,
      ...values
    })

    if (typeof user === 'string') {
      const step1 = readSteps('step1', userId) as Step1FormData
      const step2 = readSteps('step2', userId) as Step2FormData
      const saleResponse = await createSale(apiUrl, {
        ...step3,
        user,
        quantity: step2.quantity,
        grinding: step1.grinding,
        coffeeType: step1.coffeeType,
        weight: weight(step2)
      })

      hideLoading()

      if (!(saleResponse as SaleErrorResponse).code) {
        const response = saleResponse as SaleResponse
        sessionStorage.setItem('user', user)
        sessionStorage.setItem('sale', response.id)
        sessionStorage.setItem('delivery', response.priceDelivery || '0')
        sessionStorage.setItem('price', response.priceProduct || '0')

        successMessage('Información de compra guardada con éxito')
        navigate('/dosis-mensual-paso-4')
      } else {
        errorMessage('Error desconocido', 'Contáctenos al correo contacto@cofaine.pe')
      }
    } else {
      hideLoading()
      errorMessage('Usuario no pudo ser validado')
    }
  }

  return (
    <Formik initialValues={{ codeEmail: '', codePhone: '' }} onSubmit={formikSubmit} validate={validate}>
    {({ touched, errors }: FormikProps<ValidationOTPFormData>) => (
      <Form>
    <div className='validation-otp'>
      <Container>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
        <h2 className='center'>Validación de Datos</h2>
        <h3 className='center'>Ingresa los códigos enviados a tu correo y a tu número de teléfono</h3>
        <div className='validation-form'>
          <div id="emailGroup" className='title'>
            <span>Código enviado al correo: {email}</span> <span className="description">(obligatorio)</span>
            <p className="description">Revisa en No deseados</p>
          </div>
          <div role="group" className="group" aria-labelledby="emailGroup">
            <label>
              <Field type="text" placeholder="Ingresa el código de 6 dígitos" name="codeEmail"/>
            </label>
            {touched.codeEmail && errors.codeEmail && <div className='errors'>{errors.codeEmail}</div>}
          </div>

          <div id="phoneGroup" className='title'>
            <span>Código enviado por SMS al número: {phone}</span> <span className="description">(obligatorio)</span>
            <p className="description">Puede tardar unos segundos</p>
          </div>
          <div role="group" className="group" aria-labelledby="phoneGroup">
            <label>
              <Field type="text" placeholder="Ingresa el código de 6 dígitos" name="codePhone"/>
            </label>
            {touched.codePhone && errors.codePhone && <div className='errors'>{errors.codePhone}</div>}
          </div>
          <button type="submit" className='cofaine-button'>Validar</button>
        </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      </Container>
    </div>
    </Form>
    )}
    </Formik>
  )
}

export default ValidationOTP

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { Field, Form, Formik, FormikProps } from 'formik'
import { NewCardFormData, NewCardProps } from '../typedefs/props'

import './NewCard.css'
import { Culqi } from '../components/CulqiContextV4'
import { CulqiProviderV4 } from '../components/CulqiProviderV4'
import { useLocation } from 'react-router-dom'
import { errorMessage, hideLoading, startLoader, successMessage } from '../helpers/sweetAlerts'
import { CulqiOptionsV4, CulqiSettingsV4, CulqiToken, SuccessValidationPaymentMethod, ValidationPaymentMethods } from '../typedefs/culqi'
import { saveCard } from '../helpers/service'

const publicKey = process.env.REACT_APP_CULQI_PUBLIC_KEY || ''

const NewCard: React.FC<NewCardProps> = ({ apiUrl, baseUrl, webUrl }) => {
  const location = useLocation()

  const settings: CulqiSettingsV4 = {
    amount: 1000,
    title: 'Cofaine Suscripciones',
    description: 'Registrar tarjeta'
  }

  const options: CulqiOptionsV4 = {
    lang: 'auto',
    installments: true,
    paymentMethods: {
      tarjeta: true,
      yape: false,
      bancaMovil: false,
      agente: false,
      billetera: false,
      cuotealo: false
    },
    style: {
      bannerColor: '',
      logo: `${baseUrl}/cofaine.png`,
      buttonBackground: '',
      buttontext: '',
      linksColor: '',
      menuColor: '',
      priceColor: ''
    }
  }

  const validate = (values: NewCardFormData) => {
    const errors: Partial<NewCardFormData> = {}
    if (!values.email) {
      errors.email = 'El campo \'correo electrónico\' es obligatorio.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = '\'correo electrónico\' inválido.'
    }
    if (!values.card) {
      errors.card = 'El campo \'tarjeta\' es obligatorio.'
    }
    if (!values.month) {
      errors.month = 'El campo \'mes\' es obligatorio.'
    }
    if (!/^[0-9]*$/.test(values.month)) {
      errors.month = 'El campo \'mes\' debe contener solo números.'
    }
    if (values.month.length !== 2) {
      errors.month = 'El campo \'mes\' debe tener 2 caracteres.'
    }
    if (!values.year) {
      errors.year = 'El campo \'año\' es obligatorio.'
    }
    if (!/^[0-9]*$/.test(values.year)) {
      errors.year = 'El campo \'año\' debe contener solo números.'
    }
    if (values.year.length !== 4) {
      errors.year = 'El campo \'cvv\' debe tener 4 caracteres.'
    }
    if (!values.cvv) {
      errors.cvv = 'El campo \'cvv\' es obligatorio.'
    }
    if (!/^[0-9]*$/.test(values.cvv)) {
      errors.cvv = 'El campo \'cvv\' debe contener solo números.'
    }
    if (values.cvv.length > 4) {
      errors.cvv = 'El campo \'cvv\' no puede tener mas de 4 caracteres.'
    }
    return errors
  }

  const formikSubmit = (
    getPaymentOptionsAvailable: () => ValidationPaymentMethods
  ) => {
    return async (values: NewCardFormData) => {
      startLoader('Validando datos de tarjeta')
      const paymentMethods = getPaymentOptionsAvailable()
      if (paymentMethods.token.available) {
        (paymentMethods.token as SuccessValidationPaymentMethod).generate()
      }
    }
  }

  const setToken = async (token: CulqiToken) => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get('token') || ''

    const status = await saveCard(apiUrl, {
      token,
      code
    })

    hideLoading()

    if (status) {
      successMessage('Se guardo correctamente ...')
    } else {
      errorMessage('Fallo al vincular la tarjeta')
    }

    setTimeout(() => {
      window.location.href = `${webUrl}`
    }, 3000)
  }

  return (
    <CulqiProviderV4 enableEvents={false} publicKey={publicKey} settings={settings} options={options} onToken={setToken}>
    <Culqi>
        {({ getPaymentOptionsAvailable }) => {
          return (
        <Formik initialValues={{ email: '', card: '', month: '', year: '', cvv: '' }} onSubmit={formikSubmit(getPaymentOptionsAvailable)} validate={validate}>
        {({ touched, errors }: FormikProps<NewCardFormData>) => (
            <Form>
                <div className='new-card'>
                <Container>
                    <Row>
                        <Col md={3}/>
                        <Col md={6}>
                            <h3>Ingresa los datos de tu nueva tarjeta</h3>
                            <p>Este enlace es seguro, solo tú puedes ver esta información</p>
                            <div id="emailGroup" className='title'>
                            <span>Email *</span>
                            </div>
                            <div role="group" className="group" aria-labelledby="emailGroup">
                            <label>
                                <Field type="email" data-culqi="card[email]" name="email" placeholder="nombre@ejemplo.pe"/>
                            </label>
                            {touched.email && errors.email && <div className='errors'>{errors.email}</div>}
                            </div>
                            <div id="cardGroup" className='title'>
                            <span>Numero de tarjeta *</span>
                            </div>
                            <div role="group" className="group" aria-labelledby="cardGroup">
                            <label>
                                <Field type="text" data-culqi="card[number]" name="card" placeholder="**** - **** - **** - ****"/>
                            </label>
                            {touched.card && errors.card && <div className='errors'>{errors.card}</div>}
                            </div>
                            <div id="monthGroup" className='title'>
                            <span>Mes de expiración*</span>
                            </div>
                            <div role="group" className="group" aria-labelledby="monthGroup">
                            <label>
                                <Field type="text" data-culqi="card[exp_month]" name="month" placeholder="09"/>
                            </label>
                            {touched.month && errors.month && <div className='errors'>{errors.month}</div>}
                            </div>
                            <div id="yearGroup" className='title'>
                            <span>Año de expiración *</span>
                            </div>
                            <div role="group" className="group" aria-labelledby="yearGroup">
                            <label>
                                <Field type="text" data-culqi="card[exp_year]" name="year" placeholder="2025"/>
                            </label>
                            {touched.year && errors.year && <div className='errors'>{errors.year}</div>}
                            </div>
                            <div id="cvvGroup" className='title'>
                            <span>CVV *</span>
                            </div>
                            <div role="group" className="group" aria-labelledby="cvvGroup">
                            <label>
                                <Field type="text" data-culqi="card[cvv]" name="cvv" placeholder="123"/>
                            </label>
                            {touched.cvv && errors.cvv && <div className='errors'>{errors.cvv}</div>}
                            </div>
                            <button type="submit" className='cofaine-button'>Guardar tarjeta</button>
                            </Col>
                        <Col md={3}/>
                    </Row>
                    </Container>
                </div>
            </Form>
        )}
        </Formik>)
        }}
        </Culqi>
    </CulqiProviderV4>
  )
}

export default NewCard

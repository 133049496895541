import React from 'react'
import { Col, Row } from 'react-bootstrap'

import FormStep3 from '../components/FormStep3'
import { Step3Props } from '../typedefs/props'

import image3 from '../images/step-3.svg'
import './Step3.css'

const Step3: React.FC<Step3Props> = ({ sheetAPIUrl, apiUrl }) => {
  return (
    <div className="step-3">
      <Row>
        <Col md={6}>
        <p>Dosis mensual - Paso 3 de 4</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Row>
            <Col md={10}>
              <div className="block">
              <h3>Ingresa tus datos de entrega</h3>
              <p><strong>¡Envío gratis en Lima urbana!</strong></p>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
          <Row>
          <Col md={11}>
          <FormStep3 apiUrl={apiUrl} sheetAPIUrl={sheetAPIUrl}/>
          </Col>
          <Col md={1}></Col>
          </Row>
        </Col>
        <Col md={5}>
        <img src={image3} className="icon" alt="Paso 3" />
        <p><span className="description"><strong>ⓘ </strong>El costo de envío puede variar en Lima periferia y otras provincias del Perú.</span></p>
        </Col>
        <Col md={1}/>
      </Row>
    </div>
  )
}

export default Step3

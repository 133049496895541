import React, { useContext, useState } from 'react'
import { Form, Field, Formik } from 'formik'
import './Form.css'

import { FormStep4Props, Step4FormData } from '../typedefs/props'
import { createSubscription, updateSale } from '../helpers/service'
import SubscriptionContext from './SubscriptionContext'
import { Culqi } from './CulqiContextV4'
import { CulqiProviderV4 } from './CulqiProviderV4'
import { CulqiError, CulqiOptionsV4, CulqiSettingsV4, CulqiToken } from '../typedefs/culqi'
import { SaleErrorResponse, SaleRequest } from '../typedefs/request'
import { errorMessage, hideLoading, startLoader, successMessage } from '../helpers/sweetAlerts'

const publicKey = process.env.REACT_APP_CULQI_PUBLIC_KEY || ''

const FormStep4: React.FC<FormStep4Props> = ({ apiUrl, baseUrl, webUrl }) => {
  const subscriptionContext = useContext(SubscriptionContext)
  const [buttonSubmit, setButtonSubmit] = useState('Pagar ahora')

  const finalPrice = Number.parseInt((Number.parseFloat(subscriptionContext.finalPrice) * 100).toFixed(2), 10)

  const settings: CulqiSettingsV4 = {
    amount: finalPrice,
    title: 'Cofaine Suscripciones',
    description: `${subscriptionContext.quantity} bolsas (${subscriptionContext.weight})`
  }
  const options: CulqiOptionsV4 = {
    lang: 'auto',
    installments: true,
    paymentMethods: {
      tarjeta: true,
      yape: false,
      bancaMovil: false,
      agente: false,
      billetera: false,
      cuotealo: false
    },
    style: {
      bannerColor: '',
      logo: `${baseUrl}/cofaine.png`,
      buttonBackground: '',
      buttontext: '',
      linksColor: '',
      menuColor: '',
      priceColor: ''
    }
  }

  const couponCode = sessionStorage.getItem('couponCode') || ''
  const saleId = sessionStorage.getItem('sale') || 'unknown'
  const userId = sessionStorage.getItem('user') || 'unknown'

  const validate = (values: Step4FormData) => {
    values.couponCode = values.couponCode.toLocaleUpperCase()
    if (values.couponCode === couponCode || !(values.couponCode !== '' && couponCode === '')) {
      setButtonSubmit('Pagar ahora')
    } else {
      setButtonSubmit('Verificar código')
    }
  }

  const setToken = async (token: CulqiToken) => {
    const status = await createSubscription(apiUrl, {
      token,
      sale: saleId,
      user: userId,
      couponCode
    })

    if (status) {
      sessionStorage.clear()
      window.location.href = `${webUrl}/compra-exitosa`
    } else {
      window.location.href = `${webUrl}/compra-fallida`
    }
  }

  const setError = (error: CulqiError) => {
    errorMessage(error.user_message)
    setTimeout(() => {
      window.location.href = `${webUrl}/compra-fallida`
    }, 1000)
  }

  const formikSubmit = (openCulqi: () => void, setAmount: (amount: number) => void) => {
    return async (values: Step4FormData) => {
      if (values.couponCode !== couponCode) {
        startLoader('Validando cupon')

        const saleResponse = await updateSale(apiUrl, saleId, values as unknown as SaleRequest)

        hideLoading()

        if ((saleResponse as SaleErrorResponse).code) {
          errorMessage('Cupon inválido')

          sessionStorage.removeItem('discount')
          sessionStorage.removeItem('couponCode')
          subscriptionContext.setDiscount('0')
        } else {
          successMessage('Cupon aplicado correctamente')
          sessionStorage.setItem('discount', `${saleResponse.discount || 0}`)
          sessionStorage.setItem('couponCode', `${values.couponCode}`)
          subscriptionContext.setDiscount(saleResponse.discount || '0')
        }
      } else {
        setAmount(finalPrice)
        openCulqi()
      }
    }
  }

  return (
    <CulqiProviderV4 enableEvents={true} publicKey={publicKey} settings={settings} options={options} onToken={setToken} onError={setError}>
        <Culqi>
            {({ openCulqi, setAmount }) => {
              return (
                <Formik initialValues={{ couponCode }} validate={validate} onSubmit={formikSubmit(openCulqi, setAmount)}>
                <Form className='form-step-4'>
                    <div id="couponGroup" className='title'>
                        <span>¿Tienes un código de descuento?</span>
                        </div>
                        <div role="group" className="group" aria-labelledby="couponGroup">
                        <label>
                            <Field type="text" name="couponCode" placeholder="Ingresa el código"/>
                        </label>
                    </div>
                <button type="submit" className='cofaine-button'>{buttonSubmit}</button>
                </Form>
                </Formik>
              )
            }}
        </Culqi>
    </CulqiProviderV4>
  )
}

export default FormStep4

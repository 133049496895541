import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { readSteps } from '../helpers/stepsData'
import { Step1FormData, Step2FormData, Step3FormData } from '../typedefs/props'

const ProtectedRoute = (props: { children: React.ReactNode, stepToValidate: string }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isValidStep1, setIsValidStep1] = useState(true)
  const [isValidStep2, setIsValidStep2] = useState(true)
  const [isValidStep3, setIsValidStep3] = useState(true)
  const [isValidOtp, setIsValidOtp] = useState(true)
  const [isValidNewCard, setIsValidNewCard] = useState(true)

  const validateStep = (step: string) => {
    let isValid = true
    const userId = sessionStorage.getItem('userId') || 'unknown'
    switch (step) {
      case 'step1':
      {
        const step1 = readSteps('step1', userId) as Step1FormData
        isValid = step1.coffeeType !== '' && step1.grinding !== ''
        break
      }
      case 'step2':
      {
        const step2 = readSteps('step2', userId) as Step2FormData
        isValid = (typeof step2.quantity === 'string' ? Number.parseInt(step2.quantity, 10) : step2.quantity) > 0
        break
      }
      case 'step3':
      {
        const saleId = sessionStorage.getItem('sale') || 'unknown'
        isValid = saleId !== 'unknown'
        break
      }
      case 'otp': {
        const { email, phone } = readSteps('step3', userId) as Step3FormData
        isValid = email !== '' && phone !== ''
        break
      }
      case 'tarjeta': {
        const searchParams = new URLSearchParams(location.search)
        const token = searchParams.get('token')
        isValid = token !== null
        break
      }
    }
    return isValid
  }

  const getValidationsByStep = (step: string) => {
    let validations = false
    switch (step) {
      case 'step2': {
        validations = validateStep('step1')
        break
      }
      case 'step3': {
        validations = validateStep('step1') && validateStep('step2')
        break
      }
      case 'step4': {
        validations = validateStep('step1') && validateStep('step2') && validateStep('step3')
        break
      }
    }
    return validations
  }

  const setStatusStep = (step: string) => {
    switch (step) {
      case 'step2': {
        setIsValidStep1(getValidationsByStep('step2'))
        break
      }
      case 'step3': {
        setIsValidStep2(getValidationsByStep('step3'))
        break
      }
      case 'step4': {
        setIsValidStep3(getValidationsByStep('step4'))
        break
      }
      case 'otp': {
        setIsValidOtp(validateStep('otp'))
        break
      }
      case 'tarjeta': {
        setIsValidNewCard(validateStep('tarjeta'))
        break
      }
    }
  }

  useEffect(() => {
    // TODO improve this validation
    if (window.location.pathname.includes(props.stepToValidate.replace('step', ''))) {
      setStatusStep(props.stepToValidate)
    }
  }, [props.stepToValidate])

  useEffect(() => {
    if (!isValidStep1) {
      navigate('/dosis-mensual-paso-1')
    }
  }, [isValidStep1])

  useEffect(() => {
    if (!isValidStep2) {
      navigate('/dosis-mensual-paso-2')
    }
  }, [isValidStep2])

  useEffect(() => {
    if (!isValidStep3) {
      navigate('/dosis-mensual-paso-3')
    }
  }, [isValidStep3])

  useEffect(() => {
    if (!isValidOtp) {
      navigate('/dosis-mensual-paso-3')
    }
  }, [isValidOtp])

  useEffect(() => {
    if (!isValidNewCard) {
      navigate('/cambio-de-tarjeta')
    }
  }, [isValidNewCard])

  const isValidStep = (step: string): boolean => {
    let isValid = false
    switch (step) {
      case 'step2': {
        isValid = isValidStep1
        break
      }
      case 'step3': {
        isValid = isValidStep2
        break
      }
      case 'step4': {
        isValid = isValidStep3
        break
      }
      case 'otp': {
        isValid = isValidOtp
        break
      }
      case 'tarjeta': {
        isValid = isValidNewCard
        break
      }
    }
    return isValid
  }

  return (
        <React.Fragment>
            {
                isValidStep(props.stepToValidate) ? props.children : null
            }
        </React.Fragment>
  )
}
export default ProtectedRoute

import React, { useState } from 'react'
import './TopBar.css'

const TopBar: React.FC<{link: string}> = ({ link }) => {
  const [hidden, setHidden] = useState(sessionStorage.getItem('top-bar-hidden') === 'true')
  const openLink = () => {
    window.open(link, '_self')
  }
  const hideTopBar = () => {
    setHidden(true)
    sessionStorage.setItem('top-bar-hidden', 'true')
  }
  return (
      <div className={hidden ? 'top-bar-hidden' : 'top-bar'}>
        <span className="bar-close" role="button" aria-label="Cerrar el anuncio" onClick={hideTopBar}></span>
        <p onClick={openLink}>¡Envío gratis en Lima urbana, todos los meses!</p>
      </div>
  )
}

export default TopBar

import React, { useState } from 'react'
import { Form, Field, Formik, FormikProps } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { FormStep3Props, Step1FormData, Step2FormData, Step3FormData } from '../typedefs/props'
import { createSale, createUser, saveSheetStep3, sendOTP, updateSale, updateUser } from '../helpers/service'
import { getDistricts, getProvinces, getDepartments } from '../helpers/ubigeo'

import './Form.css'
import { DocumentType, ErrorCodes } from '../typedefs/enums'
import { SaleErrorResponse, SaleResponse, SendOTPRequest } from '../typedefs/request'
import { readSteps, saveSteps, weight } from '../helpers/stepsData'
import { errorMessage, hideLoading, startLoader, successMessage, warningMessage } from '../helpers/sweetAlerts'

const FormStep3: React.FC<FormStep3Props> = ({ apiUrl, sheetAPIUrl }) => {
  const navigate = useNavigate()
  const userId = sessionStorage.getItem('userId') || 'unknown'
  const step3 = readSteps('step3', userId) as Step3FormData

  const [departmentSelected, setDepartmentSelected] = useState(step3.department)
  const [provinceSelected, setProvinceSelected] = useState(step3.province)

  const [isDistrictDisabled, setDistrictDisabled] = useState(step3.district === '')
  const [isProvinceDisabled, setProvinceDisabled] = useState(step3.province === '')

  const validate = (values: Step3FormData) => {
    const errors: Partial<Step3FormData> = {}
    if (!values.email) {
      errors.email = 'El campo \'correo electrónico\' es obligatorio.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = '\'correo electrónico\' inválido.'
    }

    if (!values.phone) {
      errors.phone = 'El campo \'teléfono\' es obligatorio.'
    }
    if (!values.name) {
      errors.name = 'El campo \'nombre\' es obligatorio.'
    }
    if (!values.lastName) {
      errors.lastName = 'El campo \'apellido\' es obligatorio.'
    }
    if (!values.documentNumber) {
      errors.documentNumber = 'El campo \'numero de documento\' es obligatorio.'
    }
    if (!values.address) {
      errors.address = 'El campo \'dirección\' es obligatorio.'
    }
    if (!values.reference) {
      errors.reference = 'El campo \'referencia\' es obligatorio.'
    }
    if (!values.department) {
      errors.department = 'El campo \'departamento\' es obligatorio.'
    }
    if (!values.province) {
      errors.province = 'El campo \'provincia\' es obligatorio.'
    }
    if (!values.district) {
      errors.district = 'El campo \'distrito\' es obligatorio.'
    }
    if (!values.terms) {
      errors.terms = 'El campo \'tratamiento de datos personales\' es obligatorio.'
    }
    if (values.department) {
      setDepartmentSelected(values.department)
      setProvinceDisabled(false)
    }
    if (values.province) {
      setProvinceSelected(values.province)
      setDistrictDisabled(false)
    }
    return errors
  }

  const formikSubmit = async (values: Step3FormData) => {
    const user = sessionStorage.getItem('user')
    const sale = sessionStorage.getItem('sale')
    const step1 = readSteps('step1', userId) as Step1FormData
    const step2 = readSteps('step2', userId) as Step2FormData

    if (values.documentNumber.length !== 8) {
      values.documentType = DocumentType.PASSPORT
    }
    if (values.phone) {
      values.phone = values.phone.replace(/\s/g, '')
    }

    saveSteps('step3', values)
    startLoader(user ? 'Actualizando datos de usuario y compra' : 'Registrando usuario')

    if (user && sale) {
      await Promise.all([
        saveSheetStep3(sheetAPIUrl, values),
        updateUser(apiUrl, user, {
          ...values,
          id: values.userId
        }),
        updateSale(apiUrl,
          sale, {
            user,
            ...values,
            ...step1,
            ...step2,
            weight: weight(step2)
          })
      ])

      successMessage()
      navigate('/dosis-mensual-paso-4')
    } else {
      const [, userResponse] = await Promise.all([
        saveSheetStep3(sheetAPIUrl, values),
        createUser(apiUrl, {
          ...values,
          id: values.userId
        })
      ])

      if (typeof userResponse === 'string') {
        sessionStorage.setItem('user', userResponse)

        const saleResponse = await createSale(apiUrl, {
          ...values,
          user: userResponse,
          ...step1,
          ...step2,
          weight: weight(step2)
        })

        hideLoading()

        if (!(saleResponse as SaleErrorResponse).code) {
          const response = saleResponse as SaleResponse
          sessionStorage.setItem('sale', response.id)
          sessionStorage.setItem('delivery', response.priceDelivery || '0')
          sessionStorage.setItem('price', response.priceProduct || '0')

          successMessage()
          navigate('/dosis-mensual-paso-4')
        } else {
          errorMessage('Error desconocido', 'Contáctenos al correo contacto@cofaine.pe')
        }
      } else {
        if (userResponse.code === ErrorCodes.ALREADY_EXIST) {
          warningMessage()

          await sendOTP(apiUrl, userResponse as unknown as SendOTPRequest)

          hideLoading()
          successMessage('Códigos de verificación enviados')
          navigate('/validacion-otp')
        } else if (userResponse.code === ErrorCodes.ALREADY_HAS_SUBSCRIPTION) {
          hideLoading()
          warningMessage('Usuario ya tiene una suscripcion activa', 'Contáctenos al correo contacto@cofaine.pe')
        } else {
          hideLoading()
          errorMessage('Error desconocido', 'Contáctenos al correo contacto@cofaine.pe')
        }
      }
    }
  }

  return (
    <Formik initialValues={step3} onSubmit={formikSubmit} validate={validate}>
    {({ touched, errors }: FormikProps<Step3FormData>) => (
    <Form>
      <div id="emailGroup" className='title'>
          <span>Correo electrónico</span> <span className="description">(obligatorio)</span>
        </div>
        <div role="group" className="group" aria-labelledby="emailGroup">
          <label>
            <Field type="email" name="email" placeholder="nombre@ejemplo.pe"/>
          </label>
          {touched.email && errors.email && <div className='errors'>{errors.email}</div>}
      </div>
      <div id="phoneGroup" className='title'>
          <span>Teléfono</span> <span className="description">(obligatorio)</span>
        </div>
        <div role="group" className="group" aria-labelledby="phoneGroup">
          <label>
            <Field type="text" name="phone" placeholder="9 dígitos"/>
          </label>
          {touched.phone && errors.phone && <div className='errors'>{errors.phone}</div>}
      </div>
      <div className="title title-padding">
      <span>Nombre completo</span> <span className="description">(obligatorio)</span>
      </div>
      <Row>
        <Col md={6}>
        <div id="nameGroup" className='title little-title'>
          <span>Nombre</span>
        </div>
        <div role="group" className="group" aria-labelledby="nameGroup">
          <label>
            <Field type="text" name="name"/>
          </label>
          {touched.name && errors.name && <div className='errors'>{errors.name}</div>}
        </div>
        </Col>
        <Col md={6}>
        <div id="lastNameGroup" className='title little-title'>
          <span>Apellido</span>
        </div>
        <div role="group" className="group" aria-labelledby="lastNameGroup">
          <label>
            <Field type="text" name="lastName"/>
          </label>
          {touched.lastName && errors.lastName && <div className='errors'>{errors.lastName}</div>}
      </div>
        </Col>
      </Row>
      <div id="documentNumberGroup" className='title'>
          <span>DNI / Pasaporte</span>
        </div>
        <div role="group" className="group" aria-labelledby="documentNumberGroup">
          <label>
            <Field type="text" name="documentNumber"/>
          </label>
          {touched.documentNumber && errors.documentNumber && <div className='errors'>{errors.documentNumber}</div>}
      </div>
      <div id="addressGroup" className='title'>
          <span>Dirección de entrega</span>
        </div>
        <div role="group" className="group" aria-labelledby="addressGroup">
          <label>
            <Field type="text" name="address" placeholder="Av. Cofaine 369 dpto 101"/>
          </label>
          {touched.address && errors.address && <div className='errors'>{errors.address}</div>}
      </div>
      <div id="referenceGroup" className='title'>
          <span>Referencia</span>
        </div>
        <div role="group" className="group" aria-labelledby="referenceGroup">
          <label>
            <Field type="text" name="reference" placeholder="Cerca a ..."/>
          </label>
          {touched.reference && errors.reference && <div className='errors'>{errors.reference}</div>}
      </div>
      <div id="departmentGroup" className='title'>
          <span>Departamento</span>
        </div>
        <div role="group" className="group" aria-labelledby="departmentGroup">
          <label>
            <Field as="select" id="department" name="department">
              <option value="">Seleccione un departamento</option>
              {
                getDepartments().map(({ value, text }) => (<option value={value} key={value}>{text}</option>))
              }
            </Field>
            <div className="icon"><FontAwesomeIcon icon={faChevronDown} /></div>
          </label>
          {touched.department && errors.department && <div className='errors'>{errors.department}</div>}
      </div>
      <div id="provinceGroup" className='title'>
          <span>Provincia</span>
        </div>
        <div role="group" className="group" aria-labelledby="provinceGroup">
          <label>
            <Field as="select" id="province" name="province" disabled={isProvinceDisabled}>
              <option value="">Seleccione una provincia</option>
              {
                getProvinces(departmentSelected).map(({ value, text }) => (<option value={value} key={value}>{text}</option>))
              }
            </Field>
            <div className="icon"><FontAwesomeIcon icon={faChevronDown} /></div>
          </label>
          {touched.province && errors.province && <div className='errors'>{errors.province}</div>}
      </div>
      <div id="districtGroup" className='title'>
          <span>Distrito</span>
        </div>
        <div role="group" className="group" aria-labelledby="districtGroup">
          <label>
            <Field as="select" id="district" name="district" disabled={isDistrictDisabled}>
              <option value="">Seleccione un distrito</option>
              {
                getDistricts(provinceSelected).map(({ value, text }) => (<option value={value} key={value}>{text}</option>))
              }
            </Field>
            <div className="icon"><FontAwesomeIcon icon={faChevronDown} /></div>
          </label>
          {touched.district && errors.district && <div className='errors'>{errors.district}</div>}
      </div>
      <div role="group" className="group" aria-labelledby="termsGroup">
        <label>
          <Field type="checkbox" name="terms"/>
          Acepto el tratamiento de mis datos personales
        </label>
        {touched.terms && errors.terms && <div className='errors'>{errors.terms}</div>}
      </div>
      <button type="submit" className='cofaine-button'>Ir a pagar →</button>
    </Form>
    )}
    </Formik>
  )
}

export default FormStep3

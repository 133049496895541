import React from 'react'
import { Form, Field, Formik, FormikProps } from 'formik'
import './Form.css'
import { useNavigate } from 'react-router-dom'

import { FormStep2Props, Step2FormData } from '../typedefs/props'
import { saveSheetStep2 } from '../helpers/service'
import { readSteps, saveSteps } from '../helpers/stepsData'
import { errorMessage, hideLoading, startLoader, successMessage } from '../helpers/sweetAlerts'

const quantities = [
  { label: '1 bolsa (250gr)', value: '1' },
  { label: '2 bolsas (500gr)', value: '2' },
  { label: '3 bolsas (750gr)', value: '3' },
  { label: '4 bolsas (1 Kilo)', value: '4' }
]

const FormStep2: React.FC<FormStep2Props> = ({ sheetAPIUrl }) => {
  const navigate = useNavigate()
  const userId = sessionStorage.getItem('userId') || 'unknown'
  const step2 = readSteps('step2', userId) as Step2FormData

  const validate = (values: Step2FormData) => {
    const errors: Partial<{quantity: string}> = {}
    if (!values.quantity) {
      errors.quantity = 'El campo \'cantidad\' es obligatorio.'
    }
    return errors
  }

  const formikSubmit = async (values: Step2FormData) => {
    startLoader()
    const status = await saveSheetStep2(sheetAPIUrl, values)
    hideLoading()

    if (status) {
      successMessage()
      saveSteps('step2', values)
      navigate('/dosis-mensual-paso-3')
    } else {
      errorMessage()
    }
  }

  return (
    <Formik initialValues={step2} onSubmit={formikSubmit} validate={validate}>
    {({ touched, errors }: FormikProps<Step2FormData>) => (
    <Form>
      <div id="quantityGroup" className='title'>
          <span>Cantidad - Mensual</span> <span className="description">(obligatorio)</span>
        </div>
        <div role="group" className="group" aria-labelledby="quantityGroup">
          {
            quantities.map(({ label, value }, index) => (
            <label key={`quantity-${index}`}>
              <Field type="radio" name="quantity" value={value}/> {label}
            </label>
            ))
          }
          {touched.quantity && errors.quantity && <div className='errors'>{errors.quantity}</div>}
      </div>
      <button type="submit" className='cofaine-button'>Continuar</button>
    </Form>
    )}
    </Formik>
  )
}

export default FormStep2

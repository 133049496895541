import { createContext } from 'react'
import { CulqiContextV4Type } from '../typedefs/culqi'

const CulqiContextV4 = createContext<CulqiContextV4Type>({
  getPaymentOptionsAvailable: () => ({
    cip: { available: false, message: '' },
    cuotealo: { available: false, message: '' },
    token: { available: false, message: '' },
    yape: { available: false, message: '' }
  }),
  openCulqi: () => {},
  setAmount: (amount: number) => {},
  amount: 0,
  token: null,
  error: null
})

export const { Consumer: Culqi } = CulqiContextV4
export default CulqiContextV4

import React, { useState } from 'react'
import './Header.css'
import logo from '../images/logo_web.png'
import Navbar from './Navbar'
import Button from './Button'
import { HeaderProps } from '../typedefs/props'
import classNames from 'classnames'

const Header: React.FC<HeaderProps> = ({ links, webUrl }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const burgerButtonClass = classNames('header-burger-btn burger', {
    'burger--active': showMobileMenu
  })

  const headerMenuClass = classNames('header-menu', {
    'header-menu--active': showMobileMenu
  })

  return (
    <div className="header-fluid">
      <header className="header">
        <a href={webUrl}><img src={logo} className="logo" alt="Cofaine" /></a>
        <Navbar links={links}/>
        <Button mainClass="top-button" link={`${webUrl}/dosis-de-cafe`} label='Suscripciones'/>
        <div className="header-burger" data-animation-role="header-element">
          <button className={burgerButtonClass} onClick={toggleMobileMenu}>
            <div className="burger-box">
              <div className="burger-inner header-menu-icon-tripleLineHamburger">
                <div className="top-bun"></div>
                <div className="patty"></div>
                <div className="bottom-bun"></div>
              </div>
            </div>
          </button>
        </div>
      </header>
      <div className={headerMenuClass}>
      <div className="header-menu-bg"></div>
      <div className="header-menu-nav">
        <nav className="header-menu-nav-list">
          <div data-folder="root" className="header-menu-nav-folder header-menu-nav-folder--active">
            <div className="header-menu-nav-folder-content">
            {links.map((link, index) => (
              <div className="container header-menu-nav-item" key={index}>
                <a href={link.url}>
                  <div className="header-menu-nav-item-content">
                  {link.label}
                  </div>
                </a>
              </div>
            ))}
            </div>
            <div className="header-menu-actions"></div>
            <div className="header-menu-cta">
            <Button link={`${webUrl}/dosis-de-cafe`} mainClass='fixed-bottom-button' label='Suscripciones'/>
            </div>
          </div>
        </nav>
      </div>
      </div>
    </div>
  )
}

export default Header
